<template>
    <div class="sld_casual_question">
        <div class="top_header">
            <p class="header_title">{{ L['转接记录'] }}</p>
        </div>

        <div class="search-box">
            <el-form ref="form" :model="params" label-width="80px" @submit.prevent>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="转接记录">
                            <el-input type="text"  size="small" :placeholder="L['请输入转接记录']" v-model="params.keyword" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="会员ID">
                            <el-input type="text"  size="small" :placeholder="L['请输入会员ID']" v-model="params.memberId" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="" label-width="0px">
                            <button class="btn-search" @click="handleSearch">{{ L['查询'] }}</button>
                            <button class="btn-reset" @click="handleReset">{{ L['重置'] }}</button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <el-table :data="switchList.list" :row-class-name="tableRowClassName">
            <el-table-column type="index" :label="L['序号']" width="60">
            </el-table-column>
            <el-table-column prop="createTime" :label="L['转接时间']" width="160">
            </el-table-column>
            <el-table-column prop="content" :label="L['转接记录']">
            </el-table-column>
            <el-table-column prop="adminName" :label="L['转接客服']" width="150">
            </el-table-column>
            <el-table-column prop="adminId" :label="L['转接客服ID']" width="150">
            </el-table-column>
            <el-table-column prop="memberName" :label="L['会员']" width="150">
            </el-table-column>
            <el-table-column prop="memberId" :label="L['会员ID']" width="150">
            </el-table-column>
            <el-table-column prop="switchAdminName" :label="L['接收客服']" width="150">
            </el-table-column>
            <el-table-column prop="switchAdminId" :label="L['接收客服ID']" width="150">
            </el-table-column>
        </el-table>
        <div class="empty_ques" v-if="(!switchList.list.length > 0) && firstLoading">
            <img src="@/assets/goods/empty_data.png" alt="">
            <p>{{ L['暂无转接记录~'] }}</p>
        </div>

        <div class="sld_pagination">
            <el-pagination @prev-click="handlePrevCilickChange" @next-click="handleNextCilickChange"
                @current-change="handleCurrentChange" :currentPage="page.current" :page-size="page.pageSize"
                layout="prev, pager, next, jumper" :total="page.total" :hide-on-single-page="true">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
export default {
    name: 'autoQuestion',
    setup() {
        const msgTitle = ref(''); // 问题
        const msgContent = ref(''); // 答案
        const replySort = ref('')
        const dialogVisible = ref(false)
        const page = reactive({//页数
            current: '',
            pageSize: '',
            total: ''
        })
        const dialogTitle = ref('')
        const switchList = reactive({ list: [] })//转接记录列表
        const params = reactive({ current: 1, pageSize: 10, keyword: '', memberId: '' })
        const switchFlag = ref(false)
        const recommend = ref(false); // 推荐
        const { proxy } = getCurrentInstance()
        const contentMessage = ref('')
        const sortMessage = ref('')
        const ansMessage = ref('')
        const firstLoading = ref(false)
        const L = proxy.$getCurLanguage()
        const isPreventClick = ref(false)
        const clientWidth = ref(0);
        const answerLength = ref(8); // 回答问题输入框长度
        //获取转接记录
        const getQuesList = () => {
            proxy.$get('im-web/v3/helpdesk/admin/switch/list', params).then(res => {
                if (res.state == 200) {
                    switchList.list = res.data.list
                    page.pageSize = res.data.pagination.pageSize
                    page.total = res.data.pagination.total
                    page.current = res.data.pagination.current
                    firstLoading.value = true
                }
            })
        }

        const handleSearch = (e) => {
            params.current = 1;
            getQuesList()
        }

        const handleReset = (e) => {
            params.current = 1;
            params.keyword = '';
            params.memberId = '';
            getQuesList()
        }

        //页数改变方法--start
        const handlePrevCilickChange = (e) => {
            params.current = e
            getQuesList()
        }

        const handleNextCilickChange = (e) => {
            params.current = e
            getQuesList()
        }

        const handleCurrentChange = (e) => {
            params.current = e
            getQuesList()
        }
        //页数改变方法--end

        // 窗口调整
        const windowResize = () => {
            clientWidth.value = document.body.offsetWidth || document.documentElement.offsetWidth;
            if (clientWidth.value <= 1024) {
                answerLength.value = 4;
            } else {
                answerLength.value = 8;
            }
        }

        onMounted(() => {
            window.addEventListener('resize', windowResize);
            windowResize();
            getQuesList()
        })


        return {
            params,
            answerLength,
            dialogVisible,
            msgTitle,
            replySort,
            switchList,
            page,
            handleSearch,
            handleReset,
            handlePrevCilickChange,
            handleNextCilickChange,
            handleCurrentChange,
            switchFlag,
            recommend,
            confirm,
            dialogTitle,
            msgContent,
            contentMessage,
            sortMessage,
            ansMessage,
            firstLoading,
            L,
        }
    }
}
</script>

<style lang="scss">
.search-box {
    margin: 10px 0;
    .btn-search {
        width: 74px;
        height: 30px;
        background: #0E6FD7;
        border-radius: 3px;
        border: none;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 20px;
    }

    .btn-reset {
        width: 74px;
        height: 30px;
        background: #999;
        border-radius: 3px;
        border: none;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        margin-left: 20px;
    }
}

.el-table th>.cell {
    text-align: center;
}

.el-table .cell {
    display: flex;
    justify-content: center;
}

.el-table__empty-block {
    display: none;
}


.sld_casual_question {
    .top_header {
        width: 100%;
        height: 59px;
        border-bottom: 1px solid #F6F6F6;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header_title {
            padding-left: 10px;
            border-left: 4px solid $colorMain;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
        }

        button {
            color: #fff;
            padding: 11px;
            background-color: $colorMain;
            border-radius: 3px;
            border: none;
            margin-right: 15px;
            font-size: 14px;
            font-family: Microsoft YaHei;
        }
    }

    .top_bar {
        padding: 13px 0;
        background-color: #eee;
        display: flex;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        flex-shrink: 0;

        .number_title {
            padding: 0 80px;
        }

        .reply_title {
            width: 340px;
            text-align: center;
            /* padding: 0 180px; */
        }

        .sort_title {
            width: 200px;
            text-align: center;
            /* padding: 0 100px; */
        }

        .default_use_title {
            width: 270px;
            text-align: center;
            padding: 0 120px;
        }

        .operate_title {
            width: 310px;
            text-align: center;
            /* padding: 0 140px; */
        }

        .answer_title {
            width: 234px;
            text-align: center;
            /* padding: 0 100px; */
        }
    }

    .container {
        height: 710px;

        .con_item {
            width: 99%;
            display: flex;
            height: 50px;
            align-items: center;
            border-bottom: 1px solid #f2f2f2;

            .number {
                width: 190px;
                text-align: center;
            }

            .sort {
                text-align: center;
                width: 200px;
            }

            .default_use {
                padding: 0 117px;
            }

            .operate {
                width: 310px;
                display: flex;
                justify-content: center;


            }

            .content {
                width: 340px;
                text-align: center;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                padding-left: 10px;
                padding-right: 10px;
            }

            .answer {
                width: 240px;
                text-align: center;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                padding-left: 10px;
                padding-right: 10px;
            }

        }
    }

    .sld_pagination {
        margin-top: 20px;
    }

    .el-dialog {
        border-radius: 10px;
    }

    .el-dialog__header {
        background: #409Eff;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        height: 40px;
        padding: 11px 20px 10px;
    }

    .el-dialog__headerbtn {
        top: 13px;
    }

    .el-dialog__title {
        color: #fff;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
    }

    .el-button {
        padding: 7px 19px;
    }

    .dialog-modal {
        width: 600px !important;
        @media screen and (max-width: 640px) {
            width: 365px !important;
        }
    }

    #popTable {
        margin: 0 auto;
        border-spacing: 10px;
        width: 100%;

        .title {
            text-align: right;
        }

        .tip {
            color: #C0C4CC;
        }

        i {
            color: #e2231a;
            font-style: normal;
        }

        .message {
            font-size: 12px;
            line-height: 2;
            padding-top: 4px;
            color: #e2231a;
            font-family: Microsoft YaHei;
        }

        td {
            max-width: 284px;

            &.title {
                padding-top: 9px;
                min-width: 80px;
            }
        }
    }
}

.empty_ques {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 200px;

    p {
        color: #999999;
        font-size: 14px;
        margin-left: 10px;
    }
}
</style>